import Coffee from '@mui/icons-material/Coffee';
import ElectricalServices from '@mui/icons-material/ElectricalServices';
import LunchDining from '@mui/icons-material/LunchDining';
import WaterDropOutlined from '@mui/icons-material/WaterDropOutlined';
import Wc from '@mui/icons-material/Wc';
import Wifi from '@mui/icons-material/Wifi';

export interface TopEquipment {
    key: string;
    id: number;
    name: string;
    icon: JSX.Element;
    color?: 'eco';
}

// TODO: BUF-631: Translate the names
export const TOP_EQUIPMENTS: TopEquipment[] = [
    {
        key: 'COFFEE',
        id: 15,
        icon: <Coffee fontSize="small" />,
        name: 'Kaffeemachine',
    },
    {
        key: 'WIFI',
        id: 6,
        icon: <Wifi fontSize="small" />,
        name: 'WLAN',
    },
    {
        key: 'WC',
        id: 20,
        icon: <Wc fontSize="small" />,
        name: 'Toilette',
    },
    {
        key: 'VOLT',
        id: 12,
        icon: <ElectricalServices fontSize="small" />,
        name: '220V Steckdosen in jeder Sitzreihe',
    },
    {
        key: 'BISTRO',
        id: 37,
        icon: <LunchDining fontSize="small" />,
        name: 'Bistro-Bus',
    },
    {
        key: 'CO2',
        id: 65,
        icon: <WaterDropOutlined fontSize="small" />,
        name: 'CO2-Einsparung',
        color: 'eco',
    },
];
