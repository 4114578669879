import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Tooltip, Typography } from '@mui/material';
import React from 'react';

interface InfoIconProps {
    readonly tooltipText: string | React.ReactNode;
}

export const InfoIcon: React.FC<InfoIconProps> = ({ tooltipText }) => {
    return (
        <Tooltip arrow placement="top" title={<Typography component="legend">{tooltipText}</Typography>}>
            <InfoOutlined
                sx={{
                    fontSize: theme => theme.typography.body4.fontSize,
                    color: theme => theme.vars.palette.grey[500],
                    mb: 1,
                    ml: 0.3,
                }}
            />
        </Tooltip>
    );
};
