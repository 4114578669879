import { Box, MenuList, Tooltip } from '@mui/material';
import { useEffect, useState, type FC, type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentSelectorItem } from './payment-selector-item';
import { PriceOverrideInput } from './price-override-input';

import { ActionLoadingButton } from '@/components/elements/action/action-loading-button';
import { type ActionLoadingButtonProps } from '@/components/elements/action/mod';
import BaseModal from '@/components/modals/defaults/BaseModal';
import { useMoneyUtils } from '@/core/localization/mod';
import { type Money } from '@/entity/basic/Money';
import { type PriceOption } from '@/entity/events/task/PriceOption';
import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';

interface PriceOverrideProps {
    readonly result: BookingSearchResult;
    readonly pax: number;
    readonly label: string;
    readonly disabled?: boolean;
    readonly disabledTooltip?: string;
    readonly loading?: boolean;
    readonly buttonProps?: ActionLoadingButtonProps;
    readonly onOverride: (overridePrice: Money) => void;
}

export const PriceOverride: FC<PriceOverrideProps> = ({
    result,
    pax,
    label,
    disabled,
    disabledTooltip,
    loading,
    buttonProps,
    onOverride,
}) => {
    const { convertToSelectedCurrency, convertToCurrency } = useMoneyUtils();
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [overrideValue, setOverrideValue] = useState(convertToSelectedCurrency(result.price));

    useEffect(() => {
        setOverrideValue(convertToSelectedCurrency(result.price));
    }, [convertToSelectedCurrency, result.price]);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        handleOpen();
        buttonProps?.onClick?.(event);
    };

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleChange = (value: Money) => setOverrideValue(value);

    const handleOverride = () => {
        onOverride(convertToCurrency(overrideValue, result.price.currency));
        handleClose();
    };

    const handleSelect = (priceOption: PriceOption) => setOverrideValue(convertToSelectedCurrency(priceOption.price));

    const priceOptions = result.priceOptions;

    const button = (
        <ActionLoadingButton
            fullWidth
            category="primary"
            loading={loading}
            disabled={disabled}
            {...buttonProps}
            onClick={handleClick}>
            {label}
        </ActionLoadingButton>
    );

    return (
        <>
            {disabled ? (
                <Tooltip disableFocusListener title={disabledTooltip}>
                    <Box sx={{ width: '100%', m: 0, p: 0 }}>{button}</Box>
                </Tooltip>
            ) : (
                button
            )}
            <BaseModal
                open={open}
                title={t('operator.price_override.title')}
                content={
                    <>
                        <MenuList>
                            {priceOptions.map(priceOption => {
                                const priceDisplayRows = result.priceOptionDisplayRows.find(
                                    row => row.id === priceOption.id,
                                )?.priceDisplayRows;

                                return (
                                    <PaymentSelectorItem
                                        key={priceOption.id}
                                        priceOption={priceOption}
                                        priceDisplayRows={priceDisplayRows}
                                        pax={pax}
                                        seats={result.bus.getTotalSeats()}
                                        onSelect={handleSelect}
                                    />
                                );
                            })}
                        </MenuList>
                        <PriceOverrideInput
                            conversionWarnCurrency={result.price.currency}
                            value={overrideValue}
                            onChange={handleChange}
                        />
                    </>
                }
                handleClose={handleClose}
                onProceed={handleOverride}
            />
        </>
    );
};
