import { Box } from '@mui/material';
import React from 'react';

import { type FormColumn } from './form-grid';
import { FormLine } from './form-line';

import { useLayout } from '@/components/page/layout/mod';
import { type IPosition } from '@/features/journey-planning';

interface FormCellProps {
    readonly gridColumn: FormColumn | `${FormColumn} / ${FormColumn}`;
    readonly only?: 'mobile' | 'desktop' | 'large desktop';
    readonly showLine?: boolean | [boolean, boolean];
    readonly minHeight?: boolean;
    readonly legDetailsOf?: IPosition;
}

export const FormCell: React.FC<React.PropsWithChildren<FormCellProps>> = ({
    gridColumn,
    only,
    showLine = false,
    children,
    minHeight,
    legDetailsOf,
}) => {
    const { isDesktop, isLargeDesktop } = useLayout();

    if (isDesktop && only === 'mobile') return null;
    if (!isDesktop && only === 'desktop') return null;
    if (!isLargeDesktop && only === 'large desktop') return null;

    const position: [boolean, boolean] = Array.isArray(showLine) ? showLine : [showLine, showLine];

    return (
        <Box
            sx={{
                gridColumn,
                alignSelf: showLine ? 'stretch' : undefined,
                py: showLine ? 0 : 0.5,
                minHeight: theme => theme.spacing(minHeight ? 4 : 0),
            }}>
            {showLine ? (
                <FormLine position={position} legDetailsOf={legDetailsOf}>
                    {children}
                </FormLine>
            ) : (
                children
            )}
        </Box>
    );
};
