import { Typography, type TypographyTypeMap } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getRouteTitle } from '@/entity/journey/route/BookingRoute';
import { type DefaultStop } from '@/entity/journey/saved/DefaultStop';
import { type BookingStop } from '@/entity/journey/stop/BookingStop';
import { type IStop } from '@/features/journey-planning';

interface RouteMainTitleProps {
    readonly routeIndex: number;
    readonly stops: BookingStop[] | DefaultStop[] | IStop[];
    readonly variant?: TypographyTypeMap['props']['variant'];
    readonly color?: TypographyTypeMap['props']['color'];
}

export const RouteMainTitle: React.FC<RouteMainTitleProps> = ({
    routeIndex,
    stops,
    color = 'inherit',
    variant = 'h5',
}) => {
    const { t } = useTranslation();
    const routeTitle = getRouteTitle(stops);

    return (
        <Typography variant={variant} color={color}>
            {t('route_index', { routeIndex: routeIndex + 1 })}
            {routeTitle !== '' && <>:&nbsp;{routeTitle}</>}
        </Typography>
    );
};
