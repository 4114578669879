import { Box, type BoxProps } from '@mui/material';
import React from 'react';

import leafletBackgroundSVG from '@/assets/images/leaflet-background-3.svg';
import { Scrollbar } from '@/components/elements/scrollbar/scrollbar';
import { useAppCtx } from '@/core/app-ctx/mod';
import { iff } from '@/utils/helpers';

interface TileProps extends BoxProps {
    readonly leafletBackground?: boolean;
    readonly disableScrollbar?: boolean;
}

export const Tile: React.FC<TileProps> = ({ children, leafletBackground, disableScrollbar, sx, ...rest }) => {
    const { isIntegration } = useAppCtx();
    return (
        <Box
            className="Tile-root"
            sx={{
                height: '100%',
                ...iff(leafletBackground && !isIntegration, {
                    background: `white url(${leafletBackgroundSVG})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom left',
                    backgroundSize: 'contain',
                }),
                ...sx,
            }}
            {...rest}>
            {disableScrollbar ? children : <Scrollbar>{children}</Scrollbar>}
        </Box>
    );
};
