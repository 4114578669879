import { Box, Tooltip } from '@mui/material';
import { useEffect, useRef, useState, type ElementRef, type FC, type MouseEvent } from 'react';

import { PaymentSelectorMenu } from './payment-selector-menu';

import { ActionButton } from '@/components/elements/action/action-button';
import { type ActionLoadingButtonProps } from '@/components/elements/action/mod';
import { CLASS_TOUR_HIGHLIGHT } from '@/core/tour/mod';
import { type PriceOption } from '@/entity/events/task/PriceOption';
import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';

interface PaymentSelectorProps {
    readonly result: BookingSearchResult;
    readonly pax: number;
    readonly label: string;
    readonly disabled?: boolean;
    readonly loading?: boolean;
    readonly disabledTooltip?: string;
    readonly buttonProps?: ActionLoadingButtonProps;
    readonly highlight?: boolean;
    readonly forcedPriceOption?: PriceOption;
    readonly onSelect: (priceOption: PriceOption) => void;
}

export const PaymentSelector: FC<PaymentSelectorProps> = ({
    result,
    pax,
    label,
    disabled,
    loading,
    disabledTooltip,
    buttonProps,
    highlight,
    forcedPriceOption,
    onSelect,
}) => {
    const buttonRef = useRef<ElementRef<'button'> | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        handleOpen(event);
        buttonProps?.onClick?.(event);
    };

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
        const { priceOptions } = result;

        if (forcedPriceOption) handleSelect(forcedPriceOption);
        else if (priceOptions.length === 1) handleSelect(priceOptions[0]);
        else if (priceOptions.length > 1) setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    const handleSelect = (priceOption: PriceOption) => {
        onSelect(priceOption);
        handleClose();
    };

    useEffect(() => {
        if (highlight) buttonRef?.current?.classList.add(CLASS_TOUR_HIGHLIGHT);
        else buttonRef?.current?.classList.remove(CLASS_TOUR_HIGHLIGHT);
    }, [highlight]);

    const button = (
        <ActionButton
            ref={buttonRef}
            fullWidth
            data-cy={result.priceOptions.length > 1 ? 'payment-selector' : 'payment-selector-single'}
            category="primary"
            disabled={disabled || loading}
            {...buttonProps}
            onClick={handleClick}>
            {label}
        </ActionButton>
    );

    return (
        <>
            {disabled ? (
                <Tooltip disableFocusListener title={disabledTooltip}>
                    <Box sx={{ width: '100%', m: 0, p: 0 }}>{button}</Box>
                </Tooltip>
            ) : (
                button
            )}

            <PaymentSelectorMenu
                anchorEl={anchorEl}
                open={open}
                result={result}
                pax={pax}
                onClose={handleClose}
                onSelect={handleSelect}
            />
        </>
    );
};
