import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Alert, AlertTitle, Collapse, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type IPosition, useJourneyPlanningForm } from '@/features/journey-planning';
import { SanitizedInnerHTML } from '@/utils/SanitizedInnerHTML';

interface StopAlertProps {
    readonly isAnyFieldBeingDragged: boolean;
    readonly position: IPosition;
}

export const StopAlert: React.FC<StopAlertProps> = ({ position, isAnyFieldBeingDragged }) => {
    const { t } = useTranslation();
    const { getBookingStop } = useJourneyPlanningForm();
    const bookingStop = getBookingStop(position);

    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(!open);
    };

    if (!bookingStop?.legDetails || isAnyFieldBeingDragged) return null;

    const alerts = [...bookingStop.getAlerts(), ...bookingStop.legDetails.getAlerts()];

    return (
        <>
            {alerts.map(({ t_title, t_description, severity }) => (
                <Alert
                    key={t_title}
                    severity={severity}
                    action={
                        <IconButton size="small" onClick={toggleOpen}>
                            <KeyboardArrowDown
                                sx={{
                                    transition: t =>
                                        t.transitions.create('transform', {
                                            duration: t.transitions.duration.short,
                                        }),
                                    transform: open ? 'rotate(180deg)' : undefined,
                                }}
                            />
                        </IconButton>
                    }>
                    <AlertTitle data-cy="stop-alert">{t(t_title)}</AlertTitle>
                    <Collapse in={open}>
                        <SanitizedInnerHTML html={t(t_description)} />
                    </Collapse>
                </Alert>
            ))}
        </>
    );
};
