import { Stack, Typography, type TypographyProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BusRatingIcon } from '@/assets/icons/ratings/BusRatingIcon';

const MAX_RATING = 5;

interface BusRatingProps {
    readonly defaultValue: number;
    readonly reviewCount?: number;
    readonly textVariant?: TypographyProps['variant'];
    readonly width?: number;
    readonly height?: number;
}

export const BusRating: React.FC<BusRatingProps> = ({
    defaultValue,
    reviewCount,
    textVariant = 'body2',
    width = 15,
    height = 15,
}) => {
    const { t } = useTranslation();
    const { text, icon } = useRating(defaultValue, width, height);

    return (
        <Stack spacing={0.5}>
            <Stack direction="row" gap={0.5} alignItems="center">
                {icon}

                <Typography variant={textVariant} fontWeight="fontWeightBold">
                    {defaultValue}/{MAX_RATING}
                </Typography>

                <Typography variant={textVariant} fontWeight="fontWeightBold">
                    {text}
                </Typography>
            </Stack>

            {reviewCount && (
                <Typography variant={textVariant}>{t('number_reviews', { count: reviewCount })}</Typography>
            )}
        </Stack>
    );
};

const useRating = (rating: number, width: number, height: number) => {
    const { t } = useTranslation();

    let text;
    let icon;

    if (rating >= 4.5) {
        text = t('company_rating.outstanding');
        icon = <BusRatingIcon width={width} height={height} type="very_happy_green" />;
    }
    if (rating < 4.5 && rating >= 3.5) {
        text = t('company_rating.very_good');
        icon = <BusRatingIcon width={width} height={height} type="very_happy_light_green" />;
    }

    if (rating < 3.5 && rating >= 2.5) {
        text = t('company_rating.good');
        icon = <BusRatingIcon width={width} height={height} type="happy_yellow" />;
    }
    if (rating < 2.5 && rating >= 1.5) {
        text = t('company_rating.okay');
        icon = <BusRatingIcon width={width} height={height} type="neutral_orange" />;
    }
    if (rating < 1.5) {
        text = t('company_rating.bad');
        icon = <BusRatingIcon width={width} height={height} type="sad_red" />;
    }

    return { text, icon };
};
