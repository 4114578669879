import { BusRating } from '@/components/bus/bus-rating';
import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';

interface BusCardReviewProps {
    result: BookingSearchResult;
}

export const BusCardReview: React.FC<BusCardReviewProps> = ({ result }) => {
    const { bus } = result;

    if (bus.company.reviewScores.count === 0) return null;

    return (
        <BusRating defaultValue={bus.company.reviewScores.totalScore} reviewCount={bus.company.reviewScores.count} />
    );
};
