import CreditCard from '@mui/icons-material/CreditCard';
import ReceiptLongOutlined from '@mui/icons-material/ReceiptLongOutlined';
import { ListItemIcon, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BusPrice } from '@/components/bus/price/bus-price';
import { PaymentOption } from '@/entity/common-constants';
import { type PriceDisplayRow } from '@/entity/events/task/PriceDisplayRow';
import { type PriceOption } from '@/entity/events/task/PriceOption';

interface PaymentSelectorItemProps {
    readonly priceOption: PriceOption;
    readonly pax: number;
    readonly seats: number;
    readonly priceDisplayRows?: PriceDisplayRow[];
    readonly selected?: boolean;
    readonly onSelect: (priceOption: PriceOption) => void;
}

export const PaymentSelectorItem: FC<PaymentSelectorItemProps> = ({
    priceOption,
    pax,
    seats,
    priceDisplayRows,
    selected,
    onSelect,
}) => {
    const { t } = useTranslation();

    const handleClick = () => {
        onSelect(priceOption);
    };

    return (
        <MenuItem color="primary.main" selected={selected} onClick={handleClick}>
            <ListItemIcon sx={{ color: theme => theme.vars.palette.primary.main }}>
                <PriceOptionIcon paymentOption={priceOption.paymentOption} />
            </ListItemIcon>
            <ListItemText>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Typography color="primary" fontWeight="bold">
                        {t(`price_options.${priceOption.id}`)}
                    </Typography>
                    <BusPrice
                        seats={seats}
                        pax={pax}
                        priceToShow={priceOption.price}
                        priceDisplayRows={priceDisplayRows}
                    />
                </Stack>
            </ListItemText>
        </MenuItem>
    );
};

interface PriceOptionIconProps {
    readonly paymentOption: PaymentOption;
}

const PriceOptionIcon: FC<PriceOptionIconProps> = ({ paymentOption }) => {
    if (paymentOption === PaymentOption.INVOICE) {
        return <ReceiptLongOutlined color="inherit" />;
    }

    return <CreditCard color="inherit" />;
};
