import MenuIcon from '@mui/icons-material/Menu';
import { Divider, IconButton, Menu } from '@mui/material';
import uniqueId from 'lodash/uniqueId';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NavMenuItem } from '@/components/elements/nav/nav-menu-item';
import { type NavigationItem } from '@/components/page/navigation/useNavigation';

interface BurgerActionsProps {
    readonly items: NavigationItem[];
    readonly backItem?: NavigationItem;
}

export const BurgerActions: React.FC<BurgerActionsProps> = ({ items = [], backItem }) => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const id = uniqueId('menu');

    return (
        <>
            <IconButton aria-haspopup color="inherit" aria-controls={id} onClick={handleOpen}>
                <MenuIcon />
            </IconButton>
            <Menu
                keepMounted
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                id={id}
                onClose={handleClose}>
                {backItem && <NavMenuItem {...backItem}>{t('back_to_home')}</NavMenuItem>}
                {backItem && <Divider />}
                {items.map((item, i) => (
                    <NavMenuItem key={i} {...item} />
                ))}
            </Menu>
        </>
    );
};
