import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfoIcon } from '@/components/elements/icon/info-icon';
import { type BookingBus } from '@/entity/bus/BookingBus';

interface BusSeatsInfoProps {
    readonly bus: BookingBus;
    readonly variant?: 'default' | 'seats' | 'bus-info';
    readonly bold?: boolean;
}

const BusSeatsInfo: React.FC<BusSeatsInfoProps> = ({ bus, bold, variant = 'default' }) => {
    const { t } = useTranslation();

    const BusInfo = (
        <Typography variant="body2" fontWeight={bold ? 'fontWeightBold' : 'fontWeightRegular'}>
            {bus.getBrandAndType()}, {bus.registrationDate.year}
        </Typography>
    );

    const Seats = (
        <Stack direction="row">
            <Typography variant="body2" fontWeight={bold ? 'fontWeightBold' : 'fontWeightRegular'}>
                {t('bus.seats')} <strong>{bus.getDisplaySeats()}</strong>
            </Typography>
            <InfoIcon tooltipText={t('bus.tt_number_of_seats')} />
        </Stack>
    );

    if (variant === 'bus-info') return BusInfo;

    if (variant === 'seats') return Seats;

    return (
        <Stack direction="row" gap={2}>
            {BusInfo}
            {Seats}
        </Stack>
    );
};

export default BusSeatsInfo;
