import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { Box, Dialog, DialogContent, IconButton, styled } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState, type CSSProperties } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { type Swiper as TSwiper } from 'swiper/types';

import { BusTypeIcon } from '@/assets/icons/bus-types/BusTypeIcon';
import { AspectBox } from '@/components/elements/image/aspect-box';
import { Image } from '@/components/elements/image/image';
import { useLayout } from '@/components/page/layout/mod';
import { Document, type SizeSuffix } from '@/entity/basic/Document';
import { busTypeByPax } from '@/features/bus-selection';

interface Img {
    src: string;
    alt?: string;
}

interface GalleryProps {
    readonly images?: (string | Img | Document)[];
    readonly busSeats: number;
    readonly alt: string;
    readonly autoplay?: boolean;
    readonly disableLightBox?: boolean;
    readonly disableAspectRatio?: boolean;
    readonly initialIndex?: number;
    readonly navigation?: boolean;
    readonly fit?: CSSProperties['objectFit'];
    readonly documentSize?: SizeSuffix;
}

export const Gallery: React.FC<GalleryProps> = ({
    images = [],
    busSeats,
    alt,
    autoplay,
    disableLightBox,
    disableAspectRatio,
    navigation,
    initialIndex = 0,
    fit = 'contain',
    documentSize = 'md',
}) => {
    const { isDesktop } = useLayout();

    const fallback = images.length === 0;

    // Convert mixed images to Img type and map in defaultAlt
    const galleryImages: Img[] = useMemo(
        () =>
            images.map(image => {
                if (image instanceof Document) {
                    return {
                        alt,
                        src: image.getSrc(documentSize),
                    };
                }
                if (typeof image === 'string') {
                    return {
                        alt,
                        src: image,
                    };
                }
                return { alt, ...image };
            }),
        [images, alt, documentSize],
    );

    const [index, setIndex] = useState(
        initialIndex > galleryImages.length - 1 ? galleryImages.length - 1 : initialIndex,
    );

    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);
    const [swiper, setSwiper] = useState<TSwiper>();

    const handleChangeIndex = (idx: number) => {
        setIndex(idx);
    };

    // Lightbox
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (navigationPrevRef.current && navigationNextRef.current && swiper && !swiper.destroyed) swiper?.init();
    }, [swiper]);

    if (fallback) {
        return (
            <BusTypeIcon
                variant="filled"
                fillColor="gray"
                type={busTypeByPax(busSeats)}
                width="100%"
                height="100%"
                cursor="unset"
            />
        );
    }

    return (
        <>
            <Box position="relative" bgcolor="background.paper">
                <StyledSwiper
                    nested
                    navigation={{
                        enabled: navigation,
                        nextEl: navigationNextRef.current,
                        prevEl: navigationPrevRef.current,
                    }}
                    modules={[Navigation]}
                    autoplay={autoplay}
                    slidesPerView="auto"
                    allowTouchMove={!isDesktop}
                    init={false}
                    onSlideChange={({ activeIndex }) => handleChangeIndex(activeIndex)}
                    onSwiper={setSwiper}>
                    {galleryImages.map((image, i) => (
                        <SwiperSlide
                            key={i}
                            style={{ cursor: disableLightBox ? 'default' : 'pointer' }}
                            onClick={disableLightBox || !isDesktop ? undefined : handleOpen}>
                            {disableAspectRatio ? (
                                <Image src={image.src} loading="eager" alt={image.alt} fit={fit} />
                            ) : (
                                <AspectBox ratio="16:9">
                                    <Image src={image.src} loading="eager" alt={image.alt} fit={fit} />
                                </AspectBox>
                            )}
                        </SwiperSlide>
                    ))}

                    {isDesktop && (
                        <>
                            <IconButton ref={navigationNextRef} size="small" className="next-button">
                                <ArrowForward fontSize="small" />
                            </IconButton>

                            <IconButton ref={navigationPrevRef} size="small" className="prev-button">
                                <ArrowBack fontSize="small" />
                            </IconButton>
                        </>
                    )}
                </StyledSwiper>
            </Box>
            {!disableLightBox && (
                <Dialog open={open} maxWidth="lg" onClose={handleClose}>
                    <DialogContent
                        sx={{
                            maxWidth: '100%',
                            width: theme => theme.breakpoints.values.lg,
                            boxSizing: 'border-box',
                        }}>
                        <Gallery
                            disableLightBox
                            images={galleryImages}
                            busSeats={busSeats}
                            alt={alt}
                            initialIndex={index}
                            navigation={navigation}
                            documentSize="lg"
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

const StyledSwiper = styled(Swiper)(({ theme }) => ({
    '--button-height': '26px',

    '&:hover': {
        '.next-button,.prev-button': {
            backgroundColor: theme.vars.palette.background.paper,
            opacity: 0.7,
        },
    },

    '.next-button, .prev-button': {
        position: 'absolute',
        top: `calc(50% - calc(var(--button-height) / 2))`,
        zIndex: theme.vars.zIndex.tooltip,
        padding: theme.spacing(0.75),
        opacity: 1,
        backgroundColor: theme.vars.palette.background.paper,
        transition: theme.transitions.create('opacity', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shortest,
        }),

        '&:hover': {
            opacity: 1,
        },

        '&.swiper-button-disabled': {
            display: 'none',
        },
    },

    '.next-button': {
        right: 10,
    },

    '.prev-button': {
        left: 10,
    },

    [theme.breakpoints.up('sm')]: {
        '.next-button, .prev-button': { opacity: 0 },
    },

    [theme.breakpoints.between('sm', 'xl')]: {
        '.next-button, .prev-button': {
            padding: theme.spacing(0.5),
            '.MuiSvgIcon-root': {
                fontSize: '1rem',
            },
        },
    },
}));
