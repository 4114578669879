import { object, string, type InferType } from 'yup';

export const limitedAvailabilityFormSchema = object({
    firstName: string().required(),
    lastName: string().required(),
    email: string().email().required(),
    phoneNumber: string().optional(),
});

export interface ILimitedAvailabilityFormValues extends InferType<typeof limitedAvailabilityFormSchema> {}
