import { createContext, useContext } from 'react';

import { type BookingJourney } from '@/entity/journey/BookingJourney';
import { type BookingRoute } from '@/entity/journey/route/BookingRoute';
import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';

export interface IJourneyContext {
    journey: BookingJourney | null;
    selectedResult?: BookingSearchResult;
    selectResult: (result: BookingSearchResult) => void;
    selectRoute: (uuid?: string) => void;
    selectedRouteUuid?: string;
    isFetching: boolean;
    isFetched: boolean;
    routeResults: BookingSearchResult[];
    routeDetails?: BookingRoute;
    isOnlinePaymentAlreadySelected: boolean;
}

export const JourneyProviderContext = createContext<IJourneyContext>({
    journey: null,
    selectRoute() {},
    selectResult() {},
    selectedRouteUuid: undefined,
    routeResults: [],
    isFetching: false,
    isFetched: false,
    isOnlinePaymentAlreadySelected: true,
});

export function useJourney() {
    const context = useContext(JourneyProviderContext);

    return context;
}
