import { styled, useMediaQuery, useTheme, type GridSize, Unstable_Grid2 as Grid } from '@mui/material';
import mapValues from 'lodash/mapValues';
import React, { useMemo, useRef, useState, type Context, type ReactNode } from 'react';

import { Footer } from '@/components/page/footer/footer';
import { Header } from '@/components/page/header/header';
import { DrawerTile } from '@/components/page/layout/drawer-tile';
import { GridTile } from '@/components/page/layout/grid-tile';
import { type ILayoutContext } from '@/components/page/layout/mod';
import { type InjectedUseNavigation } from '@/components/page/navigation/useNavigation';
import { useAppCtx } from '@/core/app-ctx/mod';
import { useEmbedCtx } from '@/embed/mod';

export type Tiles = Record<string, ReactNode>;

export type ExtractNames<T extends Tiles> = Extract<keyof T, string>;

interface ITileConfig<T extends Tiles> {
    md?: GridSize;
    lg?: GridSize;
    name: ExtractNames<T>;
    mobileBase?: boolean;
}

type TileConfig<T extends Tiles> = ExtractNames<T> | ITileConfig<T>;

export interface LayoutProps<T extends Tiles> {
    readonly tiles: T;
    readonly base: TileConfig<T>[] | ((isIntegration: boolean) => TileConfig<T>[]);
    readonly drawer?: TileConfig<T>[];
    readonly useNavigation?: InjectedUseNavigation;
    readonly wrapperProvider?: React.FC;
}

export function Layout<T extends Tiles>(
    props: LayoutProps<T> & {
        readonly context: Context<ILayoutContext<ExtractNames<T>>>;
    },
): ReturnType<React.FC<LayoutProps<T>>> {
    const { context: Ctx, base, drawer = [], tiles, useNavigation, wrapperProvider } = props;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const isLargeDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const isSmallMobile = useMediaQuery(theme.breakpoints.down('small_mobile'));
    const mainRef = useRef<HTMLElement>(null);
    const { isIntegration } = useAppCtx();
    const { isEmbedding } = useEmbedCtx();

    // Get Base Tiles
    const [baseTiles, restTiles] = useMemo(() => {
        const baseConfig = typeof base === 'function' ? base(isIntegration) : base;
        const configs = intoITileConfig<T>(baseConfig);

        if (isDesktop) return [configs, []];

        const idx = configs.findIndex(config => config.mobileBase);

        if (idx === -1) return [[configs[0]], configs.slice(1)];

        const rest = [...configs];
        const mobileBase = rest.splice(idx, 1);

        return [mobileBase, rest];
    }, [base, isDesktop, isIntegration]);

    // Get Drawer Tiles
    const drawerTiles = useMemo(() => restTiles.concat(intoITileConfig(drawer)), [restTiles, drawer]);

    // Open State for Drawer Tiles
    const [drawerTilesOpen, setDrawerTilesOpen] = useState<Record<ExtractNames<T>, boolean>>(
        mapValues(tiles, () => false),
    );

    // Close specific Drawer Tile
    const handleClose = (name: ExtractNames<T>) => () => {
        setDrawerTilesOpen({ ...drawerTilesOpen, [name]: false });
    };

    // The value for the Layout Context
    const value: ILayoutContext<ExtractNames<T>> = useMemo(
        () => ({
            show: (name: ExtractNames<T>) => setDrawerTilesOpen({ ...drawerTilesOpen, [name]: true }),
            hide: (name: ExtractNames<T>) => setDrawerTilesOpen({ ...drawerTilesOpen, [name]: false }),
            showBase: () => setDrawerTilesOpen(mapValues(drawerTilesOpen, () => false)),
            isDesktop,
            isLargeDesktop,
            isSmallMobile,
            isDrawer(name: ExtractNames<T>) {
                return drawerTiles.some(dt => dt.name === name);
            },
            isDrawerOpen(name: ExtractNames<T>) {
                return drawerTilesOpen[name] ?? false;
            },
        }),
        [drawerTiles, drawerTilesOpen, isDesktop, isLargeDesktop, isSmallMobile],
    );

    const WrapperProvider = wrapperProvider || React.Fragment;

    return (
        <Ctx.Provider value={value}>
            <WrapperProvider>
                <LayoutContainer isEmbedding={isEmbedding}>
                    <Header maxWidth={false} useNavigation={useNavigation} />
                    <MainContainer ref={mainRef}>
                        {/* Render tiles in Grid */}
                        <Grid container sx={{ flexGrow: 1 }}>
                            {baseTiles.map(({ name, md, lg }, i) => {
                                return (
                                    <GridTile key={`${name}_${i}`} md={md} lg={lg}>
                                        {tiles[name]}
                                    </GridTile>
                                );
                            })}
                        </Grid>
                        {/* Render tiles in Drawer */}
                        {drawerTiles.map(({ name, md }, i) => (
                            <DrawerTile
                                key={`${name}_${i}`}
                                open={drawerTilesOpen[name]}
                                md={md}
                                isDesktop={isDesktop}
                                modalRef={mainRef}
                                onClose={handleClose(name)}>
                                {tiles[name]}
                            </DrawerTile>
                        ))}
                    </MainContainer>
                    {isDesktop && !isIntegration && <Footer />}
                </LayoutContainer>
            </WrapperProvider>
        </Ctx.Provider>
    );
}

function intoITileConfig<T extends Tiles>(mixed: TileConfig<T>[]): ITileConfig<T>[] {
    return mixed.map(m => (isITileConfig<T>(m) ? m : { name: m }));
}

function isITileConfig<T extends Tiles>(x: TileConfig<T>): x is ITileConfig<T> {
    return typeof x === 'object';
}

const LayoutContainer = styled('div')<{ isEmbedding: boolean }>(({ theme, isEmbedding }) => ({
    display: 'flex',
    flexDirection: 'column',
    ...(isEmbedding
        ? {
              minHeight:
                  'var(--bf-app-min-height, calc(100vh - var(--bf-env-boundary-offset-top, 0) - var(--bf-env-boundary-offset-bottom, 0)))',
              height: '100%',
              width: '100%',
          }
        : {
              height: '100vh',
              width: '100vw',
          }),
    // Only restrict the layout height on desktop.
    // On mobile we want the page scrollable
    [theme.breakpoints.down('md')]: {
        height: 'initial',
        minHeight: '100vh',
    },
}));

const MainContainer = styled('main')(() => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
}));
