import { Stack } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { JOURNEY_BUS_TOGGLE } from '@/api/journey/booking/journey-booking-queries';
import { ActionButton } from '@/components/elements/action/action-button';
import { type ActionButtonProps } from '@/components/elements/action/mod';
import { useLayout } from '@/components/page/layout/mod';
import { useAppProgress } from '@/core/app-progress/mod';
import { useAuth } from '@/core/auth/mod';
import { useIntegrationTranslation } from '@/core/integration-translation/mod';
import { useJourney } from '@/core/journey/mod';
import { type Money } from '@/entity/basic/Money';
import { BookingStep } from '@/entity/booking-progress/BookingProgress';
import { type PriceOption } from '@/entity/events/task/PriceOption';
import { BusRouteStatus, type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';
import { useBusDetails } from '@/features/bus-details-drawer';
import { PaymentSelector, PriceOverride } from '@/features/payment-selection';
import useToggleBusRoute from '@/hooks/useToggleBusRoute';
import { type BusSelectionTileset } from '@/main/routes/search/route.lazy';

interface BusCardConciseActionsProps {
    readonly result?: BookingSearchResult;
    readonly shouldUpdateAll?: boolean;
    readonly pax: number;
}

export const BusCardConciseActions: FC<BusCardConciseActionsProps> = ({ result, pax, shouldUpdateAll }) => {
    const { t } = useTranslation();
    const { removeBus, addBus, addBuses } = useToggleBusRoute();
    const isLoading = Boolean(useIsMutating({ mutationKey: [JOURNEY_BUS_TOGGLE] }));
    const { journey, selectResult } = useJourney();
    const { bookingModeTranslationKey } = useIntegrationTranslation();
    const { selectTab } = useBusDetails();
    const { user } = useAuth();
    const layout = useLayout<BusSelectionTileset>();
    const { currentStep } = useAppProgress();

    const isBusSelection = currentStep === BookingStep.BUS_SELECTION;

    if (!result) return null;

    const handleDetails = () => {
        selectResult(result);
        layout.show('bus_details');
        selectTab('bus-overview');
    };

    const handleBookingDetails = () => {
        selectResult(result);
        layout.show('bus_details');
        selectTab('booking-details');
    };

    const handleDelete = () => {
        void removeBus({ result, priceOptionId: result.selectedPriceOption?.id });
    };

    const handleSelect = async (priceOption: PriceOption) => {
        if (shouldUpdateAll) {
            journey?.routes.forEach(route => {
                void addBuses({
                    results: route.getSelectedSearchResults(),
                    priceOptionId: priceOption.id,
                    routeUuid: route.uuid,
                });
            });
        } else {
            void addBus({
                result,
                priceOptionId: priceOption.id,
            });
        }
    };

    const handleOverride = async (overridePrice: Money) => {
        void addBus({
            result,
            operatorPrice: overridePrice,
        });
    };

    const showDeleteBusButton = isBusSelection && journey?.amendBookingToken == null;

    const buttonProps: ActionButtonProps = {
        sx: {
            textTransform: 'unset',
            alignItems: 'center',
            '.MuiButton-startIcon': {
                mr: 0.5,
            },
        },
        size: 'small',
        category: 'secondary',
        fullWidth: false,
    };

    return (
        <Stack direction="row" flexWrap="wrap" gap={1}>
            <ActionButton {...buttonProps} onClick={handleDetails}>
                {t('bus.open_details')}
            </ActionButton>

            <ActionButton {...buttonProps} onClick={handleBookingDetails}>
                {t(`bus.open_${bookingModeTranslationKey}_details`)}
            </ActionButton>

            {user?.isOperatorOrDispatcher() ? (
                <PriceOverride
                    result={result}
                    pax={pax}
                    label={t('operator.price_override.label')}
                    buttonProps={buttonProps}
                    onOverride={handleOverride}
                />
            ) : (
                result.priceOptions.length > 1 && (
                    <PaymentSelector
                        highlight={result.status === BusRouteStatus.INVALID_PRICE_OPTION}
                        result={result}
                        pax={pax}
                        label={t('bus.change_payment_method')}
                        loading={isLoading}
                        buttonProps={buttonProps}
                        onSelect={handleSelect}
                    />
                )
            )}
            {showDeleteBusButton && (
                <ActionButton {...buttonProps} disabled={isLoading} onClick={handleDelete}>
                    {t('delete')}
                </ActionButton>
            )}
        </Stack>
    );
};
