import { createFileRoute } from '@tanstack/react-router';

import { BookingStep } from '@/entity/booking-progress/BookingProgress';

export const Route = createFileRoute('/summary')({
    beforeLoad() {
        return {
            step: BookingStep.SUMMARY,
        };
    },
});

export { Route as SummaryRoute };
