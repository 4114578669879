import { Box } from '@mui/material';
import useIntersectionObserver, { type MockIntersectionObserverEntry } from '@react-hook/intersection-observer';
import { useRef, type FC, type ReactElement, type ReactNode } from 'react';

interface AttachableRenderProps {
    observer: MockIntersectionObserverEntry | IntersectionObserverEntry;
    isAttached: boolean;
}

interface AttachableProps {
    readonly position: 'top' | 'bottom';
    readonly children?: Exclude<ReactNode, ReactElement> | FC<AttachableRenderProps>;
}

export const Attachable: FC<AttachableProps> = ({ position, children }) => {
    const ref = useRef<HTMLDivElement>(null);
    const observer = useIntersectionObserver(ref, { threshold: [1] });

    const ratio = observer.intersectionRatio ?? 1;
    const isAttached = ratio !== 0 && ratio < 1;

    return (
        <Box
            ref={ref}
            sx={{
                position: 'sticky',
                top: position === 'top' ? '-1px' : undefined,
                bottom: position === 'bottom' ? '-1px' : undefined,
                marginTop: position === 'top' ? '1px' : undefined,
                marginBottom: position === 'bottom' ? '1px' : undefined,
                zIndex: theme => theme.zIndex.appBar - 1,
            }}>
            {typeof children === 'function' ? children({ observer, isAttached }) : children}
        </Box>
    );
};
