import DirectionsBusFilled from '@mui/icons-material/DirectionsBusFilled';
import { AppBar, Box, Container, Divider, Toolbar, type Breakpoint } from '@mui/material';
import { useMatches } from '@tanstack/react-router';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { PageWarning } from '@/components/page/header/page-warning';
import { useLayout } from '@/components/page/layout/mod';
import { AccountActions } from '@/components/page/navigation/account-actions';
import { BurgerActions } from '@/components/page/navigation/burger-actions';
import { EditorActions } from '@/components/page/navigation/editor-actions';
import { NavItem } from '@/components/page/navigation/nav-item';
import { SettingsActions } from '@/components/page/navigation/settings-actions';
import {
    useDefaultNavigation,
    type InjectedUseNavigation,
    type NavigationItem,
} from '@/components/page/navigation/useNavigation';

const BACK_ITEM: NavigationItem = {
    navigate: { to: '/', search: {} },
    icon: <DirectionsBusFilled />,
};

interface HeaderProps {
    readonly maxWidth?: Breakpoint | false;
    readonly useNavigation?: InjectedUseNavigation;
}

export const Header: React.FC<HeaderProps> = ({ maxWidth = false, useNavigation = useDefaultNavigation }) => {
    const { isDesktop } = useLayout();
    const { items, disableBack } = useNavigation();
    const { t } = useTranslation();
    const matches = useMatches();

    const displItems = useMemo(
        () => (isDesktop ? items : items.filter(item => matches.some(match => match.id === (item.navigate.to ?? '/')))),
        [isDesktop, items, matches],
    );

    const headerRef = useRef<HTMLElement | null>(null);
    const scrollInto = useRef(true);

    useEffect(() => {
        if (scrollInto.current && !isDesktop && headerRef.current != null) {
            headerRef.current.scrollIntoView(true);
            scrollInto.current = false;
        }
    }, [isDesktop]);

    return (
        <>
            <AppBar ref={headerRef} position="relative" color="default">
                <Container maxWidth={maxWidth}>
                    <Toolbar disableGutters>
                        <Box sx={{ flexGrow: 1, display: 'flex', gap: { xs: 0, md: 1 } }}>
                            {isDesktop ? (
                                !disableBack && (
                                    <>
                                        <NavItem {...BACK_ITEM}>{isDesktop ? t('back_to_home') : ''}</NavItem>
                                        <Divider
                                            flexItem
                                            orientation="vertical"
                                            sx={{ borderColor: theme => theme.vars.palette.primary.main }}
                                        />
                                    </>
                                )
                            ) : (
                                <BurgerActions items={items} backItem={disableBack ? undefined : BACK_ITEM} />
                            )}
                            {displItems.map((item, i) => (
                                <NavItem key={i} {...item} icon={undefined} />
                            ))}
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            <EditorActions />
                            <AccountActions />
                            <SettingsActions />
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <PageWarning />
        </>
    );
};
