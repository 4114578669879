import { Stack } from '@mui/material';
import React from 'react';

import { BreakTimeChip } from './break-time-chip';
import { LocalBusChip } from './local-bus-chip';
import { StatisticsChip } from './statistics-chip';
import { StopChip } from './stop-chip';
import { TripCompanyChip } from './trip-company-chip';

import { useJourneyPlanningForm, type IPosition, type IStop } from '@/features/journey-planning';

interface StopChipsProps {
    readonly position: IPosition;
    readonly isAnyFieldBeingDragged: boolean;
}

export const StopChips: React.FC<StopChipsProps> = ({ position, isAnyFieldBeingDragged }) => {
    const { stopsArray, getBookingStop } = useJourneyPlanningForm();
    const { fields, update } = stopsArray;

    if (isAnyFieldBeingDragged) return null;

    const stop = fields[position.index];

    if (!stop?.location) return null;

    const bookingStop = getBookingStop(position);

    const handleSave = (stop: Partial<IStop>) => {
        update(position.index, {
            ...fields[position.index],
            ...stop,
        });
    };

    return (
        <Stack direction={{ xs: 'column', sm: 'row' }} flexWrap="wrap" gap={1} mt={1}>
            <StopChip position={position} visible={['intermediate']}>
                <BreakTimeChip bookingStop={bookingStop} onSave={handleSave} />
            </StopChip>
            <StopChip position={position} visible={['intermediate']}>
                <LocalBusChip stop={bookingStop} position={position} onSave={handleSave} />
            </StopChip>
            <StopChip position={position} visible={['last']}>
                <StatisticsChip />
            </StopChip>
            <StopChip position={position} visible={['first', 'intermediate', 'last']}>
                <TripCompanyChip name={stop?.tripCompany?.companyName} />
            </StopChip>
        </Stack>
    );
};
