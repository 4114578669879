import { Box } from '@mui/material';
import React from 'react';

import { useLayout } from '@/components/page/layout/mod';
import { iff } from '@/utils/helpers';

export type FormColumn = 'left' | 'line' | 'main' | 'off';
type ColumnsLayout = Record<FormColumn, string>;

const COLUMNS: ColumnsLayout = {
    left: '1fr',
    line: '40px',
    main: '2fr',
    off: '40px',
};

const COLUMNS_MOBILE: ColumnsLayout = {
    left: '0',
    line: '40px',
    main: '1fr',
    off: '40px',
};

function gridTemplateColumns(isDesktop: boolean, columns?: ColumnsLayout): string {
    return Object.entries(columns ?? (isDesktop ? COLUMNS : COLUMNS_MOBILE))
        .map(([name, value]) => `[${name}] ${value}`)
        .join(' ');
}

interface FormGridProps {
    readonly gutterBottom?: true;
    readonly columns?: Record<FormColumn, string>;
}

export const FormGrid: React.FC<React.PropsWithChildren<FormGridProps>> = ({ gutterBottom, columns, children }) => {
    const { isDesktop } = useLayout();

    return (
        <Box
            sx={{
                display: 'grid',
                justifyContent: 'stretch',
                columnGap: 1,
                alignItems: 'center',
                gridTemplateColumns: gridTemplateColumns(isDesktop, columns),
                mb: iff(gutterBottom, isDesktop ? 4 : 2),
            }}>
            {children}
        </Box>
    );
};
