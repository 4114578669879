import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '@/components/elements/action/action-button';
import BaseModal, { type BaseModalProps } from '@/components/modals/defaults/BaseModal';

const AcknowledgeModal: React.FC<BaseModalProps> = props => {
    const { t } = useTranslation();
    const {
        handleClose,
        onProceed,
        proceedText = t('confirm'),
        proceedButtonType = 'normal',
        hideCloseX = true,
    } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const ModalActionsContent = (
        <Grid container justifyContent="flex-end" spacing={2} direction={isMobile ? 'column-reverse' : 'row'}>
            <Grid item>
                <ActionButton
                    sx={{ width: isMobile ? '100%' : 'auto' }}
                    category="primary"
                    intent={proceedButtonType}
                    onClick={() => {
                        onProceed?.();
                        handleClose();
                    }}>
                    {proceedText}
                </ActionButton>
            </Grid>
        </Grid>
    );

    const modalProps = {
        ...props,
        hideCloseX,
        actions: ModalActionsContent,
        handleClose() {
            onProceed?.();
            handleClose();
        },
    };

    return <BaseModal {...modalProps} />;
};

export default AcknowledgeModal;
