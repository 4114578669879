import { Divider, Stack } from '@mui/material';
import { type FC } from 'react';

import { TopEquipmentsIcons } from '../elements/top-equipment-icons';

import BusSeatsInfo from '@/components/bus/bus-seats-info';
import { type BookingBus } from '@/entity/bus/BookingBus';

interface BusCardInfoProps {
    readonly bus: BookingBus;
}

export const BusCardInfo: FC<BusCardInfoProps> = ({ bus }) => {
    const { topEquipments } = bus;

    return (
        <Stack spacing={0.5}>
            <BusSeatsInfo bus={bus} variant="bus-info" />

            <Stack
                direction="row"
                divider={
                    topEquipments.length > 0 ? <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} /> : null
                }>
                <BusSeatsInfo bus={bus} variant="seats" />
                <TopEquipmentsIcons topEquipments={topEquipments} />
            </Stack>
        </Stack>
    );
};
