import { Stack, Step, StepLabel, Stepper, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '@/components/elements/action/action-button';
import ConsiderSplitRoutes from '@/components/modals/considerations/ConsiderSplitRoutes';
import DriverRoomsChoice from '@/components/modals/considerations/DriverRoomsChoice';
import BaseModal, { type BaseModalProps } from '@/components/modals/defaults/BaseModal';
import { type BookingJourney } from '@/entity/journey/BookingJourney';
import { type IJourney } from '@/features/journey-planning';
import { FULL_BREAK_MINUTES } from '@/utils/constants';
import { nTimes } from '@/utils/helpers';
import { SanitizedInnerHTML } from '@/utils/SanitizedInnerHTML';

// t('search_form.modal.title.local_bus_and_split_route')
// t('search_form.modal.title.consider_split_route')
// t('search_form.modal.title.driver_rooms_choice')
// t('search_form.modal.title.no_break_warning')
// t('search_form.modal.step_title.local_bus_and_split_route')
// t('search_form.modal.step_title.consider_split_route')
// t('search_form.modal.step_title.driver_rooms_choice')
// t('search_form.modal.step_title.no_break_warning')
export type ModalStep =
    | 'local_bus_and_split_route'
    | 'consider_split_route'
    | 'driver_rooms_choice'
    | 'no_break_warning';

export interface JourneyConsiderationsProps extends BaseModalProps {
    readonly journey: BookingJourney;
    readonly formValues: IJourney;
    readonly modals: ModalStep[];
}

export const JourneyConsiderationsModal: React.FC<JourneyConsiderationsProps> = props => {
    const { modals, formValues, journey, onProceed, handleClose } = props;

    const { t } = useTranslation();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const [activeStep, setActiveStep] = useState(0);
    const activeStepName = modals[activeStep];

    const formMethods = useForm<IJourney>({
        defaultValues: {
            ...formValues,
            routes: formValues.routes.map(route => ({
                ...route,
                confirmSplit: modals.includes('consider_split_route'),
            })),
            companyProvidesDriverRooms: modals.includes('driver_rooms_choice'),
        },
    });

    const routesCount = formValues.routes.length;

    // Array of booleans, each representing whether the user has confirmed the split for the corresponding route
    const confirmSplit: boolean[] = formMethods.watch(nTimes(routesCount).map(i => `routes.${i}.confirmSplit` as any));
    // Array of booleans, each representing the corresponding route if it has a long break and has a CONSIDER_SPLIT_ROUTE status
    const routesWithLongBreakAndSplit = journey.routes.map(route =>
        route.stops.some(
            stop =>
                stop.stayDuration.as('minutes') >= FULL_BREAK_MINUTES && stop.statuses.includes('CONSIDER_SPLIT_ROUTE'),
        ),
    );
    // Whether the journey has a long break that has no split status
    const journeyHasLongBreakAndNoSplit = journey.routes.some(route =>
        route.stops.some(stop => stop.stayDuration.as('minutes') >= FULL_BREAK_MINUTES && !stop.hasSplitStatus()),
    );

    const stepsToShow = () => {
        // Show accommodation preference if one of these fulfill:
        // 1. Journey has a stop with a long break and no split status
        // 2. One of the routes has a long break and the user doesn't want to split that specific route
        const showDriverRoomsChoice =
            journeyHasLongBreakAndNoSplit ||
            routesWithLongBreakAndSplit.some((hasLongBreak, i) => hasLongBreak && !confirmSplit[i]);

        return showDriverRoomsChoice ? modals : modals.filter(step => step !== 'driver_rooms_choice');
    };

    const isLastStep = activeStep === stepsToShow().length - 1;
    const isFirstStep = activeStep === 0;

    const handleBack = () => {
        if (isFirstStep) return handleClose();
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleNext = () => {
        if (isLastStep) {
            onProceed?.(formMethods.getValues());
            return handleClose();
        }
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const ModalContent = (
        <FormProvider {...formMethods}>
            <form noValidate>
                <Stack gap={4}>
                    {stepsToShow().length > 1 && (
                        <Stepper orientation={isDesktop ? 'horizontal' : 'vertical'} activeStep={activeStep}>
                            {stepsToShow().map(step => (
                                <Step key={step}>
                                    <StepLabel>{t(`search_form.modal.step_title.${step}`)}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    )}

                    <CurrentlyActiveStep activeStepName={activeStepName} formValues={formValues} />
                </Stack>
            </form>
        </FormProvider>
    );

    const ModalActions = (
        <Stack width="100%" direction={{ xs: 'column-reverse', sm: 'row' }} gap={1} justifyContent="space-between">
            <ActionButton intent="negative" category="secondary" type="button" onClick={handleBack}>
                {isFirstStep ? t('close') : t('back')}
            </ActionButton>
            <ActionButton type="button" onClick={handleNext}>
                {isLastStep ? t('search_form.submit') : t('next')}
            </ActionButton>
        </Stack>
    );

    const title =
        stepsToShow().length > 1 ? t('search_form.modal.title.main') : t(`search_form.modal.title.${activeStepName}`);

    const augmentedModalProps = {
        ...props,
        title,
        content: ModalContent,
        actions: ModalActions,
    };

    return <BaseModal {...augmentedModalProps} />;
};

interface CurrentlyActiveStepProps {
    readonly formValues: IJourney;
    readonly activeStepName: ModalStep;
}

const CurrentlyActiveStep: React.FC<CurrentlyActiveStepProps> = ({ activeStepName, formValues }) => {
    const { t } = useTranslation();

    switch (activeStepName) {
        case 'no_break_warning': {
            return t('search_form.modal.description.no_break_warning');
        }
        case 'local_bus_and_split_route': {
            return <SanitizedInnerHTML html={t('stop.leg_details.status.local_bus_and_split_route.description')} />;
        }
        case 'consider_split_route': {
            return <ConsiderSplitRoutes formValues={formValues} />;
        }
        case 'driver_rooms_choice': {
            return <DriverRoomsChoice />;
        }
        default: {
            return null;
        }
    }
};
