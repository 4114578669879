import { Stack } from '@mui/material';
import { type DraggableProvided } from '@rechnerherz/dnd';
import React from 'react';

import { FormCell } from '../grid/form-cell';
import { FormGrid } from '../grid/form-grid';
import { FormRow } from '../grid/form-row';

import { StopChips } from './field-chips/stop-chips';
import { StopAdd } from './stop-add';
import { StopAlert } from './stop-alert';
import { StopArrival } from './stop-arrival';
import { StopAvatar } from './stop-avatar';
import { StopDeparture } from './stop-departure';
import { StopLocation } from './stop-location';
import { StopRemove } from './stop-remove';

import { SearchFormFirstIntermediateTourPlan } from '@/core/tour/SearchFormFirstIntermediateTour';
import { TourProvider } from '@/core/tour/TourProvider';
import { type IPosition, type IStop } from '@/features/journey-planning';

interface StopRowProps {
    readonly position: IPosition;
    readonly stop: IStop;
    readonly draggableProvided: DraggableProvided;
    readonly isAnyFieldBeingDragged: boolean;
    readonly isDragDisabled: boolean;
}

export const StopRow: React.FC<StopRowProps> = ({
    position,
    stop,
    draggableProvided,
    isAnyFieldBeingDragged,
    isDragDisabled,
}) => {
    const stopRow = (
        <FormGrid>
            <FormRow only="mobile">
                <FormCell showLine={!position.first && !isAnyFieldBeingDragged} gridColumn="line" />
                <FormCell gridColumn="main">
                    <StopArrival position={position} stop={stop} isAnyFieldBeingDragged={isAnyFieldBeingDragged} />
                </FormCell>
            </FormRow>
            <FormRow>
                <FormCell only="desktop" gridColumn="left">
                    <Stack gap={0.5}>
                        <StopArrival position={position} stop={stop} isAnyFieldBeingDragged={isAnyFieldBeingDragged} />
                        <StopDeparture
                            position={position}
                            stop={stop}
                            isAnyFieldBeingDragged={isAnyFieldBeingDragged}
                        />
                    </Stack>
                </FormCell>
                <FormCell
                    showLine={[!position.first && !isAnyFieldBeingDragged, !position.last && !isAnyFieldBeingDragged]}
                    legDetailsOf={position}
                    gridColumn="line">
                    <StopAvatar
                        isAnyFieldBeingDragged={isAnyFieldBeingDragged}
                        isDragDisabled={isDragDisabled}
                        draggableProvided={draggableProvided}
                        position={position}
                        stop={stop}
                    />
                </FormCell>

                <FormCell gridColumn="main">
                    <StopLocation position={position} />
                    <StopChips position={position} isAnyFieldBeingDragged={isAnyFieldBeingDragged} />
                </FormCell>
                <FormCell gridColumn="off">
                    <StopRemove position={position} isAnyFieldBeingDragged={isAnyFieldBeingDragged} />
                </FormCell>
            </FormRow>
            <FormRow only="mobile">
                <FormCell showLine={!position.last && !isAnyFieldBeingDragged} gridColumn="line" />
                <FormCell gridColumn="main">
                    <StopDeparture position={position} stop={stop} isAnyFieldBeingDragged={isAnyFieldBeingDragged} />
                </FormCell>
            </FormRow>
            <FormRow>
                <FormCell minHeight showLine={!position.last && !isAnyFieldBeingDragged} gridColumn="line">
                    <StopAdd position={position} isAnyFieldBeingDragged={isAnyFieldBeingDragged} />
                </FormCell>
                <FormCell gridColumn="main">
                    <StopAlert isAnyFieldBeingDragged={isAnyFieldBeingDragged} position={position} />
                </FormCell>
            </FormRow>
        </FormGrid>
    );

    if (!position.last && position.index === 1)
        return (
            <TourProvider autoStart="chained" plan={SearchFormFirstIntermediateTourPlan}>
                {stopRow}
            </TourProvider>
        );

    return stopRow;
};
