import { type LinkOptions } from '@tanstack/react-router';
import { type ReactNode } from 'react';

export interface NavigationItem<F = never> {
    navigate: LinkOptions;
    icon?: ReactNode;
    disabled?: boolean;
    filter?: F extends never ? never : (filterProps: F) => boolean;
    completed?: boolean;
}

export type InjectedUseNavigation = () => UseNavigation;

export interface UseNavigation {
    items: NavigationItem[];
    disableBack?: boolean;
}

export function useDefaultNavigation(): UseNavigation {
    return {
        items: [],
        disableBack: false,
    };
}
