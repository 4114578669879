import BrushOutlined from '@mui/icons-material/BrushOutlined';
import FactCheckOutlined from '@mui/icons-material/FactCheckOutlined';
import LocalOffer from '@mui/icons-material/LocalOffer';
import MapOutlined from '@mui/icons-material/MapOutlined';
import Search from '@mui/icons-material/Search';
import { useMemo } from 'react';

import { type NavigationItem, type UseNavigation } from '@/components/page/navigation/useNavigation';
import { useAppProgress } from '@/core/app-progress/mod';
import { BookingStep } from '@/entity/booking-progress/BookingProgress';

const STEP_ICONS: Record<BookingStep, JSX.Element> = {
    [BookingStep.ROUTE_PLANNING]: <MapOutlined />,
    [BookingStep.BUS_SELECTION]: <Search />,
    [BookingStep.CUSTOMER_OFFER]: <LocalOffer />,
    [BookingStep.CONTACT_DATA]: <FactCheckOutlined />,
    [BookingStep.SUMMARY]: <FactCheckOutlined />,
    [BookingStep.BOOKING_CONFIRMATION]: <BrushOutlined />,
    [BookingStep.OFFER_CONFIRMATION]: <BrushOutlined />,
};

export function BookingNavigation(): UseNavigation {
    const { progress, currentStep } = useAppProgress();
    const { steps, isComplete, isOfferAndNotOperator } = progress;

    const items: NavigationItem[] = useMemo(() => {
        const currentIdx = steps.findIndex(step => step.name === currentStep);

        return steps.map((step, idx) => ({
            icon: STEP_ICONS[step.name],
            navigate: step.navigate,
            disabled:
                // Disable if progress is complete or step is not completed or step is after the current step
                (isComplete || !step.completed || idx > currentIdx) &&
                // Never disable the current active step
                step.name !== currentStep,

            completed: step.completed,
        }));
    }, [steps, isComplete, currentStep]);

    return {
        disableBack: isOfferAndNotOperator,
        items,
    };
}
